import React from 'react';

function Status() {
  return (
    <div>
      <h1>Application Status</h1>
      <p>Track the progress of my application.</p>
    </div>
  );
}

export default Status;