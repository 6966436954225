import React from 'react';

function Assistance() {
  return (
    <div>
      <h1>Assistance</h1>
      <p>Feel free to reach out me.</p>
    </div>
  );
}

export default Assistance;